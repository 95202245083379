import { useEffect, useRef, useState } from 'react';

const BEFORE_STATE = 'BEFORE';
const ENTERED_STATE = 'ENTERED';

export const useWaypointPixel = (onBefore: ()=> void, onEnter: ()=> void, pixelsDown = 500): void => {
    const stateRef = useRef(BEFORE_STATE);

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY < pixelsDown) {
                if (stateRef.current !== BEFORE_STATE) onBefore();
                stateRef.current = BEFORE_STATE;
            } else if (stateRef.current !== ENTERED_STATE) {
                onEnter();
                stateRef.current = ENTERED_STATE;
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);
};

interface WayPointShowProps {
    children: JSX.Element,
    enterPosition: number,
}

export const WayPointShow = ({ children, enterPosition = 500 }: WayPointShowProps): JSX.Element|null => {
    const [show, setShow] = useState(false);
    useWaypointPixel(() => setShow(false), () => setShow(true), enterPosition);

    return show ? children : null;
};

export const useWaypointShow = (enterPosition = 500): boolean => {
    const [show, setShow] = useState(false);
    useWaypointPixel(() => setShow(false), () => setShow(true), enterPosition);

    return show;
};
