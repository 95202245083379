import React, { Fragment } from 'react';

import { NavigationProvider } from '../data/context/NavigationContext';

import Footer from '../components/Footer/Footer';
import TopNav from '../components/Navigation/TopNavigation/TopNavigation';
import SideNav from '../components/Navigation/SideNavigation/SideNavigation';
import FloatingNav from '../components/Navigation/FloatingNavigation/FloatingNavigation';

interface LayoutProps {
    children: React.ReactNode
}

const DefaultLayout = ({ children }: LayoutProps): JSX.Element => (
    <Fragment>
        <NavigationProvider>
            <TopNav />
            <FloatingNav />
            <SideNav />
        </NavigationProvider>
        <main>
            {children}
        </main>
        <Footer />
    </Fragment>
);

export default DefaultLayout;
