import React from 'react';
import { CSSTransition } from 'react-transition-group';
import { Link } from 'gatsby';

import { useNavigationContext } from '../../../data/context/NavigationContext';

import * as styles from './SideNavigation.module.scss';

import BlankButton from '../../Buttons/BlankButton/BlankButton';
import Decorator from '../../Decorator/Decorator';
import IconCross from '../../Icons/IconCross';

const SideNav = (): JSX.Element => {
    const { sidebarNavIsOpen, setSidebarNavIsOpen } = useNavigationContext();

    const transitionClassNames = {
        enter: styles.SidebarNavTransitionEnter,
        enterActive: styles.SidebarNavTransitionEnterActive,
        enterDone: styles.SidebarNavTransitionEnterDone,
        exit: styles.SidebarNavTransitionExit,
        exitActive: styles.SidebarNavTransitionExitActive,
        // exitDone: styles.SidebarNavTransitionExitDone
    };

    return (
        <CSSTransition in={sidebarNavIsOpen} mountOnEnter unmountOnExit classNames={transitionClassNames} timeout={{ enter: 600, exit: 300 }}>
            <div className="hook-side-nav-wrapper">
                <button className={`${styles.SideNavOverlay}`} type="button" onClick={() => { setSidebarNavIsOpen(false); }} />
                <nav className={`${styles.SideNav}`}>
                    <header className={styles.SideNavHeader}>
                        <span className="text-smallcaps">Menu</span>
                        <BlankButton accessibleName="Close menu" icon={<IconCross size="24px" stroke="white" />} onClick={() => { setSidebarNavIsOpen(false); }} />
                    </header>
                    <ul className={styles.SideNavList}>
                        <li>
                            <Link to="/" activeClassName={styles.SideNavLinkActive} className={`text-heading ${styles.SideNavLink}`} onClick={() => { setSidebarNavIsOpen(false); }}>Home</Link>
                        </li>
                        <li>
                            <Link to="/design/" activeClassName={styles.SideNavLinkActive} className={`text-heading ${styles.SideNavLink}`} onClick={() => { setSidebarNavIsOpen(false); }}>Design</Link>
                        </li>
                        <li>
                            <Link to="/build/" activeClassName={styles.SideNavLinkActive} className={`text-heading ${styles.SideNavLink}`} onClick={() => { setSidebarNavIsOpen(false); }}>Build</Link>
                        </li>
                        <li>
                            <Link to="/projects/" activeClassName={styles.SideNavLinkActive} className={`text-heading ${styles.SideNavLink}`} onClick={() => { setSidebarNavIsOpen(false); }}>Our projects</Link>
                        </li>
                        <li>
                            <Link to="/carbon-manifesto/" activeClassName={styles.SideNavLinkActive} className={`text-heading ${styles.SideNavLink}`} onClick={() => { setSidebarNavIsOpen(false); }}>Carbon manifesto</Link>
                        </li>
                        <li>
                            <Link to="/contact/" activeClassName={styles.SideNavLinkActive} className={`text-heading ${styles.SideNavLink}`} onClick={() => { setSidebarNavIsOpen(false); }}>Contact us</Link>
                        </li>
                        <li>
                            <Decorator />
                        </li>
                        <li>
                            <Link to="/project-planner/" activeClassName={styles.SideNavLinkActive} className={`text-heading gold ${styles.SideNavLink}`} onClick={() => { setSidebarNavIsOpen(false); }}>Start your project</Link>
                        </li>
                    </ul>
                </nav>
            </div>
        </CSSTransition>
    );
};

export default SideNav;
