exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-build-tsx": () => import("./../../../src/pages/build.tsx" /* webpackChunkName: "component---src-pages-build-tsx" */),
  "component---src-pages-carbon-manifesto-tsx": () => import("./../../../src/pages/carbon-manifesto.tsx" /* webpackChunkName: "component---src-pages-carbon-manifesto-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-design-tsx": () => import("./../../../src/pages/design.tsx" /* webpackChunkName: "component---src-pages-design-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-pages-project-planner-tsx": () => import("./../../../src/pages/project-planner.tsx" /* webpackChunkName: "component---src-pages-project-planner-tsx" */),
  "component---src-pages-project-submitted-tsx": () => import("./../../../src/pages/project-submitted.tsx" /* webpackChunkName: "component---src-pages-project-submitted-tsx" */),
  "component---src-pages-projects-contentful-project-slug-tsx": () => import("./../../../src/pages/projects/{ContentfulProject.slug}.tsx" /* webpackChunkName: "component---src-pages-projects-contentful-project-slug-tsx" */),
  "component---src-pages-projects-tsx": () => import("./../../../src/pages/projects.tsx" /* webpackChunkName: "component---src-pages-projects-tsx" */)
}

