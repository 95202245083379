import React from 'react';

import * as styles from './HorizontalRule.module.scss';

const ColorTypes = {
    'bronze-400': 'bg-bronze-400',
    'bronze-700': 'bg-bronze-700',
    'gold-gradient': 'gold'
};

interface HorizontalRuleProps {
    color?: keyof typeof ColorTypes
}

const HorizontalRule = ({ color = 'bronze-700' }: HorizontalRuleProps): JSX.Element => (
    <hr className={`${styles.HorizontalRule} ${ColorTypes[color]}`} />
);

export default HorizontalRule;
