import React, { Fragment } from 'react';

import BaseIcon, { IconProps } from './BaseIcon';

const IconArrowRight = (props: IconProps): JSX.Element => (
    <BaseIcon {...props}>
        <Fragment>
            <path d="M37 14.994L47 24.994L37 34.994" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M47 24.994H1" strokeLinecap="round" strokeLinejoin="round" />
        </Fragment>
    </BaseIcon>
);

export default IconArrowRight;
