import React, { Fragment } from 'react';

import BaseIcon, { IconProps } from './BaseIcon';

const IconLeaf = (props: IconProps): JSX.Element => (
    <BaseIcon {...props}>
        <Fragment>
            <path d="M31.54 39.1C28.1829 41.0066 24.4002 42.0382 20.54 42.1C17.3849 42.0959 14.3113 41.097 11.7566 39.2454C9.20192 37.3938 7.29613 34.7837 6.31036 31.7865C5.32458 28.7893 5.30901 25.5576 6.26587 22.551C7.22272 19.5444 9.10328 16.9161 11.64 15.04C11.64 15.04 16.54 10.1 28.54 10.1C34.0819 10.3773 39.5794 8.98376 44.32 6.1C44.4678 6.02121 44.6326 5.98 44.8 5.98C44.9674 5.98 45.1323 6.02121 45.28 6.1C45.432 6.16325 45.5652 6.26454 45.6668 6.39413C45.7684 6.52373 45.8349 6.67727 45.86 6.84C46.78 12.14 48.34 29.86 31.54 39.1Z" strokeLinecap="round" strokeLinejoin="round" fill="none" />
            <path d="M26.54 23.1C20.3298 24.8158 14.5097 27.7156 9.39999 31.64L1.62 38.28" strokeLinecap="round" strokeLinejoin="round" />
        </Fragment>
    </BaseIcon>
);

export default IconLeaf;
