import React, { Fragment } from 'react';

import BaseIcon, { IconProps } from './BaseIcon';

const IconMail = (props: IconProps): JSX.Element => (
    <BaseIcon {...props}>
        <Fragment>
            <path d="M1 7.90799H47V39.908H1V7.90799Z" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M45.822 9.07199L24 26.908L2.17801 9.07199" strokeLinecap="round" strokeLinejoin="round" />
        </Fragment>
    </BaseIcon>
);

export default IconMail;
