import React, { Fragment } from 'react';

import BaseIcon, { IconProps } from './BaseIcon';

const IconMapSearch = (props: IconProps): JSX.Element => (
    <BaseIcon {...props}>
        <Fragment>
            <path d="M14.673 10.3766V42.3766" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M28.673 20.5966V0.596619" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M20.6731 38.3166L15.2531 42.1766C15.0862 42.3032 14.8825 42.3716 14.6731 42.3716C14.4636 42.3716 14.2599 42.3032 14.0931 42.1766L1.09306 32.8966C0.963626 32.8025 0.858165 32.6792 0.785202 32.5367C0.712239 32.3943 0.673822 32.2367 0.673058 32.0766V1.59663C0.669879 1.41374 0.719016 1.23375 0.814683 1.07785C0.91035 0.921952 1.04856 0.796626 1.21306 0.716629C1.36894 0.619043 1.54915 0.567291 1.73306 0.567291C1.91697 0.567291 2.09717 0.619043 2.25306 0.716629L14.6731 10.3766L28.0931 0.776629C28.2635 0.658794 28.4658 0.595676 28.6731 0.595676C28.8803 0.595676 29.0826 0.658794 29.2531 0.776629L42.2531 10.0766C42.3821 10.1665 42.4877 10.2861 42.5608 10.4253C42.6339 10.5646 42.6724 10.7194 42.6731 10.8766V22.5966" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M42.673 33.5966C42.673 35.3767 42.1452 37.1167 41.1563 38.5968C40.1673 40.0768 38.7617 41.2303 37.1172 41.9115C35.4727 42.5927 33.6631 42.771 31.9172 42.4237C30.1714 42.0764 28.5678 41.2193 27.3091 39.9606C26.0504 38.7019 25.1932 37.0983 24.846 35.3524C24.4987 33.6066 24.6769 31.797 25.3581 30.1525C26.0393 28.5079 27.1929 27.1023 28.6729 26.1134C30.153 25.1245 31.893 24.5966 33.673 24.5966C36.06 24.5966 38.3492 25.5448 40.037 27.2327C41.7248 28.9205 42.673 31.2097 42.673 33.5966Z" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M40.033 39.9566L46.673 46.5966" strokeLinecap="round" strokeLinejoin="round" />
        </Fragment>
    </BaseIcon>
);

export default IconMapSearch;
