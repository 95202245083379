import React from 'react';
import { Link } from 'gatsby';
import * as styles from '../Button.module.scss';

const ButtonSizeTypes = {
    small: styles.ButtonSmall,
    medium: styles.ButtonMedium,
};

interface ButtonProps {
    label: string,
    href: string,
    bordered?: boolean,
    pseudoLink?: boolean,
    icon?: JSX.Element,
    iconPosition?: 'before'|'after',
    size?: keyof typeof ButtonSizeTypes
}

const LinkButton = ({
    label, href, bordered = false, pseudoLink = false, icon, iconPosition = 'after', size = 'medium'
}: ButtonProps): JSX.Element => (
    <Link to={href} className={`valign--center ${styles.Button} ${ButtonSizeTypes[size]} ${(bordered) ? styles.BorderButton : ''} ${(pseudoLink) ? 'pseudo-link' : ''}`}>
        {
            (icon && iconPosition === 'before')
                ? icon
                : null
        }
        <span>{label}</span>
        {
            (icon && iconPosition === 'after')
                ? icon
                : null
        }
    </Link>
);

export default LinkButton;
