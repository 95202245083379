import React, { Fragment } from 'react';

import BaseIcon, { IconProps } from './BaseIcon';

const IconMenu = (props: IconProps): JSX.Element => (
    <BaseIcon {...props}>
        <Fragment>
            <path d="M48 11H0" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M48 24.3333H0" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M48 37.6665H0" strokeLinecap="round" strokeLinejoin="round" />
        </Fragment>
    </BaseIcon>
);

export default IconMenu;
