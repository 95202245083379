import React from 'react';
import { CSSTransition } from 'react-transition-group';

import BlankButton from '../../Buttons/BlankButton/BlankButton';
import IconMenu from '../../Icons/IconMenu';

import * as styles from './FloatingNavigation.module.scss';

import { useNavigationContext } from '../../../data/context/NavigationContext';
import { useWaypointShow } from '../../../hooks/useWaypointPixel';

const FloatingNavigation = (): JSX.Element => {
    const { setSidebarNavIsOpen } = useNavigationContext();
    const show = useWaypointShow(100);

    const transitionClassNames = {
        enter: styles.FloatingNavTransitionEnter,
        enterActive: styles.FloatingNavTransitionEnterActive,
        // enterDone: styles.FloatingNavTransitionEnterDone,
        exit: styles.FloatingNavTransitionExit,
        exitActive: styles.FloatingNavTransitionExitActive,
        // exitDone: styles.FloatingNavTransitionExitDone
    };

    return (

        <CSSTransition in={show} mountOnEnter unmountOnExit classNames={transitionClassNames} timeout={{ enter: 600, exit: 300 }}>
            <div className={styles.FloatingNav}>
                <BlankButton className={styles.FloatingNavButton} icon={<IconMenu size="24px" />} iconPosition="before" label="Menu" labelStyle="text-smallcaps" onClick={() => { setSidebarNavIsOpen(true); }} />
            </div>
        </CSSTransition>

    );
};

export default FloatingNavigation;
