import React, {
    createContext, useContext, useState, useMemo
} from 'react';

interface NavigationContext {
    sidebarNavIsOpen: boolean,
    setSidebarNavIsOpen: React.Dispatch<React.SetStateAction<boolean>>
}

const NavigationContext = createContext<NavigationContext>({} as NavigationContext);

interface NavigationProviderProps {
    children: React.ReactNode
}

export const NavigationProvider = ({ children }: NavigationProviderProps): JSX.Element => {
    const [sidebarNavIsOpen, setSidebarNavIsOpen] = useState(false);

    const value = useMemo(() => ({
        sidebarNavIsOpen,
        setSidebarNavIsOpen
    }), [sidebarNavIsOpen]);

    return (
        <NavigationContext.Provider value={value}>
            {children}
        </NavigationContext.Provider>
    );
};

export const useNavigationContext = (): NavigationContext => {
    const context = useContext(NavigationContext);

    if (context === undefined) {
        throw new Error('useNavigationContext must be used within a NavigationProvider');
    }

    return context;
};
