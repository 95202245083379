import React from 'react';

const FillTypes = {
    'slate-900': 'fill-slate-900',
    'bronze-400': 'fill-bronze-400',
    white: 'fill-white',
    none: 'fill-transparent'
};

const StrokeTypes = {
    'slate-400': 'stroke-slate-400',
    'slate-900': 'stroke-slate-900',
    'bronze-400': 'stroke-bronze-400',
    plum: 'stroke-plum',
    white: 'stroke-white',
    none: 'none'
};

export interface BaseIconProps {
    size?: React.SVGAttributes<SVGSVGElement>['width'] | React.SVGAttributes<SVGSVGElement>['height'],
    fill?: keyof typeof FillTypes,
    stroke?: keyof typeof StrokeTypes,
    className?: string,
    children: React.ReactNode
}

export type IconProps = Omit<BaseIconProps, 'children'>

const BaseIcon = ({
    className, children, fill = 'none', stroke = 'bronze-400', size = '48px',
}: BaseIconProps): JSX.Element => (
    <svg viewBox="0 0 48 48" width={size} height={size} className={`${className} ${FillTypes[fill]} ${StrokeTypes[stroke]}`} strokeWidth="2">
        {children}
    </svg>
);

export default BaseIcon;
