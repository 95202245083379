import React, { MouseEventHandler } from 'react';

import * as styles from './BlankButton.module.scss';

interface BlankButtonProps {
    className?: string,
    label?: string,
    accessibleName?: string,
    labelStyle?: 'text-smallcaps',
    onClick?: MouseEventHandler<HTMLButtonElement>,
    icon?: JSX.Element,
    iconPosition?: 'before'|'after'
}

const BlankButton = ({
    className, label, labelStyle = 'text-smallcaps', icon, iconPosition = 'after', accessibleName, onClick
}: BlankButtonProps): JSX.Element => (
    <button
        type="button"
        className={`${styles.BlankButton} space-x-1 valign--center ${className}`}
        onClick={onClick}
        name={(accessibleName) || label}
        aria-label={(accessibleName) || label}
    >
        {
            (icon && iconPosition === 'before')
                ? icon
                : null
        }
        {
            (label)
                ? <span className={labelStyle}>{label}</span>
                : null
        }
        {
            (icon && iconPosition === 'after')
                ? icon
                : null
        }
    </button>
);

export default BlankButton;
