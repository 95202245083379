import React from 'react';

import BaseIcon, { IconProps } from './BaseIcon';

const IconCross = (props: IconProps): JSX.Element => (
    <BaseIcon {...props}>
        <path d="M47 1L1 47M1 1L47 47" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </BaseIcon>
);

export default IconCross;
