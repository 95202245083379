// extracted by mini-css-extract-plugin
export var SideNav = "SideNavigation-module--SideNav--7EjLR";
export var SideNavHeader = "SideNavigation-module--SideNavHeader--SBm2s";
export var SideNavLink = "SideNavigation-module--SideNavLink--pJt2k";
export var SideNavLinkActive = "SideNavigation-module--SideNavLinkActive--8+sn8";
export var SideNavList = "SideNavigation-module--SideNavList--Ag1Y0";
export var SideNavOverlay = "SideNavigation-module--SideNavOverlay--V7jNO";
export var SidebarNavTransitionEnter = "SideNavigation-module--SidebarNavTransitionEnter--8AJOI";
export var SidebarNavTransitionEnterActive = "SideNavigation-module--SidebarNavTransitionEnterActive--hCUdc";
export var SidebarNavTransitionEnterDone = "SideNavigation-module--SidebarNavTransitionEnterDone--jLCRb";
export var SidebarNavTransitionExit = "SideNavigation-module--SidebarNavTransitionExit--WkIz8";
export var SidebarNavTransitionExitActive = "SideNavigation-module--SidebarNavTransitionExitActive--CSUn0";