/* eslint @typescript-eslint/no-explicit-any :0 */
/* eslint react/jsx-props-no-spreading :0 */

import React from 'react';
import DefaultLayout from './src/layouts/default';

interface WrapPageElementProps {
    element: React.ReactNode,
    props: any
}

export const wrapPageElement = ({ element, props }: WrapPageElementProps): JSX.Element => (
    <DefaultLayout {...props}>
        {element}
    </DefaultLayout>
);
