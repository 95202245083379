import React, { Fragment } from 'react';

import BaseIcon, { IconProps } from './BaseIcon';

const IconPhone = (props: IconProps): JSX.Element => (
    <BaseIcon {...props}>
        <Fragment>
            <path d="M14.984 20.026C15.3556 19.6545 15.6504 19.2135 15.8515 18.7281C16.0526 18.2427 16.1561 17.7224 16.1561 17.197C16.1561 16.6716 16.0526 16.1513 15.8515 15.6659C15.6504 15.1805 15.3556 14.7395 14.984 14.368L10.74 10.126C9.98989 9.37611 8.97266 8.95485 7.912 8.95485C6.85134 8.95485 5.83411 9.37611 5.084 10.126L2.756 12.454C1.78039 13.4324 1.17236 14.7176 1.03463 16.0924C0.896888 17.4672 1.23788 18.8475 2 20C8.84995 30.3106 17.6894 39.15 28 46C29.1527 46.7619 30.5331 47.1025 31.9079 46.9644C33.2828 46.8263 34.5678 46.2179 35.546 45.242L37.876 42.914C38.6259 42.1639 39.0471 41.1467 39.0471 40.086C39.0471 39.0253 38.6259 38.0081 37.876 37.258L33.632 33.016C32.8819 32.2661 31.8647 31.8448 30.804 31.8448C29.7433 31.8448 28.7261 32.2661 27.976 33.016L26.562 34.43C21.8179 30.5279 17.4641 26.1741 13.562 21.43L14.984 20.026Z" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M24 1C30.1 1 35.9501 3.42321 40.2635 7.73654C44.5768 12.0499 47 17.9 47 24" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M24 9C27.9782 9 31.7936 10.5804 34.6066 13.3934C37.4196 16.2064 39 20.0218 39 24" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M24 17C25.8565 17 27.637 17.7375 28.9497 19.0503C30.2625 20.363 31 22.1435 31 24" strokeLinecap="round" strokeLinejoin="round" />
        </Fragment>
    </BaseIcon>
);

export default IconPhone;
